import { state } from '@angular/animations';
import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, lastValueFrom, map } from 'rxjs';
import Helper from 'src/app/helper/Helper';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { IS_UPLOAD_REQUEST, MultipleFileUpload } from 'src/app/types/types';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth-service.service';
import { MenuItem } from 'primeng/api';
import { SplitButton } from 'primeng/splitbutton';
import { error } from 'console';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { Table } from 'primeng/table';
import { AppService } from 'src/app/app.service';
declare var require
const Swal = require('sweetalert2')


@Component({
  selector: 'app-view-unit',
  templateUrl: './view-unit.component.html',
  styleUrls: ['./view-unit.component.scss']
})
export class ViewUnitComponent implements OnInit {

  @ViewChildren('formField', { read: ElementRef }) formFields!: QueryList<ElementRef>;
  @ViewChild('template') imageTemplateRef: TemplateRef<any>;
  @ViewChild('dtPayments') dataTablePayments: Table;
  @ViewChild('dtDonations') dataTableDonations: Table;
  
  
  environment = environment
  moment = moment

  unitForm: FormGroup = undefined; // Define the form group
  unit : any = undefined
  loading = false
  allUnitPayments = []
  totalPaymentsCount = 0;
  allUnitDonations = []
  totalDonationsCount = 0;
  selectedPayment = undefined
  selectedDonation = undefined
  viewPaymentDialog = false
  viewDonationDialog = false

  loggedInUser
  editing: boolean = false
  uuid: string = ""

  allConstituencies = []
  filteredDistricts: any[] = [];
  allDistricts = []
  filteredSecretaries: any[] = [];
  allMembers = []
  bEditSecretary = false

  constructor(private httpClient: HttpClient, private route: ActivatedRoute, private router: Router, private fb: FormBuilder,private toastrService: ToastrService,
    private authService: AuthService, protected modalService: NgbModal, private appService: AppService) { }

  ngOnInit() {

    this.authService.getCurrentUser().subscribe({
      next:((user) => {
        this.loggedInUser = user
      })
    })

    lastValueFrom(combineLatest([
      this.httpClient.get<any>(`${environment.serverUrl}/districts/all`),
     ]).pipe(
      map(([a]) => { 
        this.allDistricts = a.data
      })
    ))
  }

  ngAfterViewInit(){
    if( this.router.url.split("/")[this.router.url.split("/").length-2] == 'edit-unit' ){
      this.editing = true
      const uuid = this.route.snapshot.params['uuid']
      this.uuid = uuid;
      this.loadUnitDetails(uuid)
      this.loadUnitMembers()
    }
    else{
      this.appService.setTitle("NEW UNIT DETAILS")
      this.appService.setMenuItems([{label:'ALL UNITS', routerLink: '/units',icon:"pi pi-sitemap"},{label:"ADD NEW UNIT",icon:"pi pi-sitemap", disabled:true}])
      this.bEditSecretary = true
      this.unitForm = this.fb.group({
        name: ['', Validators.required],
        unitNumber: ['', Validators.required],
        address: ['', Validators.required],
        startedDate: [undefined, Validators.required],
        districtId: [''],
        districtName: [''],
        secretaryId: [''],
        secretaryName: [''],
      });
    }
  }

  loadUnitDetails(uuid){

    return lastValueFrom(this.httpClient.get(`${environment.serverUrl}/units/${uuid}`)).then( (result:any) => {  
      this.unit = result
      this.appService.setTitle(this.unit?.name.toUpperCase())
      this.appService.setMenuItems([{label:'ALL UNITS', routerLink: '/units',icon:"pi pi-sitemap"},{icon:"pi pi-sitemap", label:this.unit.name.toUpperCase(),disabled:true}])

      if( !this.unit.secretary ) this.bEditSecretary = true
      this.unitForm = this.fb.group({
        name: [this.unit.name, Validators.required],
        unitNumber: [this.unit.unitNumber, Validators.required],
        address: [this.unit.address, Validators.required],
        startedDate: [this.unit.startedDate ? new Date(this.unit.startedDate): undefined, Validators.required],
        districtId: [this.unit.district?.id],
        districtName: [this.unit.district?.name],
        secretaryId: [this.unit.secretary?.id],
        secretaryName: [this.unit.secretary?.name],
      });
    })
    .catch(e => {
      Helper.processError(e)
      this.router.navigate(['units'])
    });
  }

  loadUnitMembers(){
    this.httpClient.get(`${environment.serverUrl}/units/members?unitUUID=${this.uuid}`).subscribe(
      (result:any) => {
        this.allMembers = result.data;
      },
      (error) => {
        console.error('Error loading unit members:', error);
      }
    );
  }

  loadUnitPayments(event: any) {
    this.loading = true;

    // Prepare the parameters for pagination, sorting, and filtering
    const params: any = {
      take: event.rows, // Number of records to take
      skip: event.first, // Starting point
      order: event.sortField || 'id', // Sorting field
      orderDir: event.sortOrder === 1 ? 'ASC' : 'DESC', // Sorting direction
    };

    const httpParams = new HttpParams({ fromObject: params });

    this.httpClient.get(`${environment.serverUrl}/units/${this.uuid}/payments`, { params: httpParams }).subscribe(
      (result:any) => {
        this.allUnitPayments = result.data;
        this.totalPaymentsCount = result.count
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching Unit Payments:', error);
        this.loading = false;
      }
    );
  }

  loadUnitDonations(event: any) {
    this.loading = true;

    // Prepare the parameters for pagination, sorting, and filtering
    const params: any = {
      take: event.rows, // Number of records to take
      skip: event.first, // Starting point
      order: event.sortField || 'id', // Sorting field
      orderDir: event.sortOrder === 1 ? 'ASC' : 'DESC', // Sorting direction
    };

    const httpParams = new HttpParams({ fromObject: params });

    this.httpClient.get(`${environment.serverUrl}/units/${this.uuid}/donations`, { params: httpParams }).subscribe(
      (result:any) => {
        this.allUnitDonations = result.data;
        this.totalDonationsCount = result.count
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching Unit Payments:', error);
        this.loading = false;
      }
    );
  }

  onDonationRowSelect(event){
    this.selectedDonation = event.data
    this.viewDonationDialog = true
  }

  onPaymentRowSelect(event){
    this.selectedPayment = event.data
    this.viewPaymentDialog = true
  }

  save() {
    
    if (!this.unitForm.valid) {
      this.unitForm.markAllAsTouched();
      this.focusFirstInvalidControl()
      Helper.showMessageAlert('Error',`Please fill in all the mandatory fields before saving.`,'error');
      return;
    }

    let postUrl = `${environment.serverUrl}/units/`

    const formData: FormData = new FormData();    
    for (const field in this.unitForm.controls) { 
      const control = this.unitForm.get(field);
      if( control.value && control.value != null)
        formData.append(field,control.value)
    }
    if( this.unit && this.unit?.id){
      formData.append("unitUUID", this.unit.uuid);
    }

    this.httpClient.post(postUrl, formData,{
      context:new HttpContext().set(IS_UPLOAD_REQUEST, true)
      }).subscribe({
        next: (v:any) => {
          console.log(v)
          Helper.showMessageAlert('Success','Unit added successfully!','success');
          this.router.navigate(['units'])
        },
        error: (e) => {
          console.error(e);
          Helper.processError(e);
        },
        complete: () => console.info('complete')
      });
  }

  editSecretary(){
    Helper.showConfirmationAlert(`Are you sure?`,`You want to change the Secretary for this unit?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        this.bEditSecretary = true
        this.unitForm.patchValue({
          secretaryId:'',
          secretaryName:'',
        });
      }
    })
  }

  onSecretarySelected(e){
    this.unitForm.patchValue({
      secretaryId:e.value.id,
      secretaryName:e.value.name,
    });
  }
  filterSecretary(event) {
    
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.allMembers.length; i++) {
      let member = this.allMembers[i];
      if (member.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(member);
      }
    }
    this.filteredSecretaries = filtered;
  }

  onDistrictSelected(e){
    this.unitForm.patchValue({
      districtId:e.value.id,
      districtName:e.value.name,
    });
  }
  filterDistrict(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.allDistricts.length; i++) {
      let district = this.allDistricts[i];
      if (district.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(district);
      }
    }
    this.filteredDistricts = filtered;
  }

  private focusFirstInvalidControl() {
    const formControls = this.unitForm.controls;

    // Iterate through the controls using Object.keys
    for (const key of Object.keys(formControls)) {
      const control = this.unitForm.get(key);

      // If the control is invalid, find the corresponding input element and focus it
      if (control?.invalid) {
        const invalidControl = this.formFields.find((element) => {
          const inputElement = element.nativeElement.querySelector('input');
          // Check if the input element's name matches the key or fallback to nativeElement.name
          return inputElement?.name === key || element.nativeElement.name === key;
        });

        if (invalidControl) {
          const inputElement = invalidControl.nativeElement.querySelector('input');

          // Focus the input element if it exists, otherwise fallback to the native element
          if (inputElement) {
            inputElement.focus();
          } else {
            invalidControl.nativeElement.focus();
          }
        }
        break; // Focus only on the first invalid control
      }
    }
  }

  refreshPaymentsList(){
    this.loadUnitPayments(this.dataTablePayments.createLazyLoadMetadata())
  }

  refreshDonationsList(){
    this.loadUnitDonations(this.dataTableDonations.createLazyLoadMetadata())
  }

  getBackgroundColor(payment){
    if( payment.status === 'Paid'){
      return '#BA8E23'
    }
    else if( payment.status === 'Confirmed'){
      return '#009688'
    }
    else if( payment.status === 'Declined'){
      return '#FF0000'
    }
  }

  getColor(payment){
    if( payment.status === 'Paid'){
      return '#FFF'
    }
    else if( payment.status === 'Confirmed'){
      return '#FFF'
    }
    else if( payment.status === 'Declined'){
      return '#FFF'
    }
  }

}
