<p-table
  #dt
  [value]="allPayments"
  [paginator]="true"
  [rows]="10"
  [totalRecords]="totalCount"
  selectionMode="single"
  [lazy]="true"
  [reorderableColumns]="true"
  [rowsPerPageOptions]="[10, 20, 50]"
  (onLazyLoad)="loadData($event)"
  scrollHeight="calc(100vh - 405px)"
  [scrollable]="true" 
  styleClass="mt-3"
  dataKey="id"
>
<ng-template pTemplate="caption">
  <div class="flex flex-wrap justify-content-end gap-2">
      <p-button pRipple class="btn-rounded" label="Refresh Data" icon="pi pi-refresh" text (onClick)="refreshList()" />
  </div>
</ng-template>
  <!-- Expand Button Column -->
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th>Requested Date</th>
      <th>Payment Date</th>
      <th style="text-align:right">Amount Paid</th>
      <th>Status</th>
      <th>Payout Event</th>
      <th>Requested By</th>
      <th>Approved By</th>
      <th>Declined By</th>
      <th>Payment Type</th>
      <th>Reference #</th>
      <th></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-payment>
    <tr [pSelectableRow]="payment">
      <td pFrozenColumn></td>
      <td pFrozenColumn>{{ payment.requestedDate ? moment(payment.requestedDate).format("Do MMM YYYY") : "N/A"}}</td>
      <td pFrozenColumn>{{ payment.paymentDate ? moment(payment.paymentDate).format("Do MMM YYYY") : "N/A"}}</td>
      <td style="text-align:right">{{ payment.amountPaid ? (payment.amountPaid | currency:'INR':'symbol') : "N/A"}}</td>
      <td [ngStyle]="{'background-color' : getBackgroundColor(payment), 'color':getColor(payment)}">{{ payment.status.toUpperCase() }}</td>
      <td >{{ payment.payoutEvent ? payment.payoutEvent.name : "N/A"}}</td>
      <td >{{ payment.requestedBy ? payment.requestedBy.name : "N/A"}}</td>
      <td >{{ payment.approvedBy ? payment.approvedBy.name : "N/A"}}</td>
      <td >{{ payment.declinedBy ? payment.declinedBy.name : "N/A"}}</td>
      <td >{{ payment.paymentType ? payment.paymentType : "N/A" }}</td>
      <td >{{ payment.paymentReferenceNumber ? payment.paymentReferenceNumber : "N/A" }}</td>
      <td>
        <div class="flex align-items-center justify-content-center gap-2">
          <!-- <button style="background-color: white !important;color:#000" pButton [raised]="true" (click)="viewTransaction(payment)" class="btn btn-primary m-r-5">VIEW</button> -->
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr  style="height:calc(100vh - 405px)">
        <td colspan="11" style="text-align: center;">There are no member payments</td>
    </tr>
  </ng-template>
</p-table>